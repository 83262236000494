// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authRedirectUri: '/',
  authPostLogoutRedirectUri: '/',
  datexApplicationApiUrl: '/api/',
  integrationHubConfigApiUrl: 'https://integrationhub-configuration-qa.azurewebsites.net/configapi/',
  integrationHubConfigurationApiScopes: ['api://6270aed0-a332-4cde-b676-b6f6267147d4/.default'],
  legacyConnectorsConfigApiUrl: 'https://datex-file-watcher-configuration-api-qa.azurewebsites.net/',
  legacyConnectorsConfigApiScopes: ['api://542d189e-dd86-4316-a36a-77a779d17741/.default'],
  integrationHubApiUrl: 'https://api-qa.datexintegrationhub.com/api/',
  integrationHubApiScopes: ['api://b02d93e9-7c88-4a3d-87d8-5e9dad386f32/.default'],
  integrationHubLoggingApiUrl: 'https://integrationhub-logging-qa.azurewebsites.net/hubapi/',
  integrationHubLoggingApiScopes: ['api://1f13a31f-bcb4-4ec6-bb09-b57aec88e9d6/.default'],
  datexAlertApiUrl: 'https://datex-alert-services-qa.azurewebsites.net/odata/',
  datexAlertApiScopes: ['api://aca0e1e7-0a87-4ce2-b98e-0dfce8aa4ae4/.default'],
  datexAlertRecommndedSolutionApiUrl: 'https://ca-alerts-suggestion-api-qa.ambitioustree-9be13291.eastus.azurecontainerapps.io/',
  datexAlertRecommndedSolutionApiScopes: ['api://251e5db6-bef0-41da-8db4-67f3035ff5cc/.default'],
  kustoQueryApiUrl: '',
  kustoQueryApiScopes: [],
  footPrintAdaptorApiUrl: 'https://datexintegrationhub-footprint-qa.azurewebsites.net/api/',
  footPrintAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  shopifyAdaptorApiUrl: 'https://datexintegrationhub-shopify-qa.azurewebsites.net/api/',
  shopifyAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  wooCommerceAdaptorApiUrl: 'https://datexintegrationhub-woocommerce-qa.azurewebsites.net/api/',
  wooCommerceAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  bigCommerceAdaptorApiUrl: 'https://datexintegrationhub-bigcommerce-qa.azurewebsites.net/api/',
  bigCommerceAdaptorApiScopes: ['api://266491f6-9fb1-4cb1-b3bb-56aefd699e86/.default'],
  grapcityLicenseKey: '*.wavelength.host,654486356437283#B1n2C8UTTzN6ckFWRvdGdHtkYT3CdxczSwE5M9djZTVEVTNEORRVN8pmd9JDUnJHOaZnaXl7QkRkNDFmUM3UNZVkcqNkWIN6doNkWtBVVNN5ZPlTR5l6QylFVS9mMrUEWQVUaGREaRd6MQpEZwEHRHtCVxEWa0BjQRFTW92UaThXMKdVSPZGdzMXVyFVV7QTZCBDSzJDd6R5Ur2CR8ZUO8YFTF34YhBTbhtWeL5mZXhGdp56YR9EelVna0pHT6c6aChmW8Y5N4cXSxE7RX3EeUpWQwRlT6BzcCdWdMtCczd5SmtiS8cDR7U6MjdEV4ZkI0IyUiwiI8MjQ5Q4Q9MjI0ICSiwSOwMTM4MTM4EjM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsIiMxgDN4ADIyEDNwMjMwIjI0ICdyNkIsICdz3GaugGdn9WZsVmdhdnLqIiOiMXbEJCLigXZ4FGRiojIh94QiwiIzgjM7MDN6UzM6gDN4UjNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQTlUUSZXOMZ6NolTZu9WdNRXMFtWNkd4UWJHWad4SvMmVYhGRapXUWdWOQRjYmNDR8xmQiVnN5Z7LLdVZ9JkN9sCTxIzbyokZjFUWjJXQ9FVQ0R4NCB5NaZ6KatSUatybWNGVxrk33',
  ediLogicAppTriggerUrl: 'https://as2test.datexintegrationhub.com',
  wavelengthAccountConnectorUrl: 'https://wavelength-connector-edi-qa.eastus.cloudapp.azure.com/',
  authtok: 'gJxjmNebq3g0M9XIEcXHa2PiODl8roVO',
  copilotApiUrl: 'https://copilot.wavelength.host/api/codecopilot/analyze/',
  copilotApiScopes: ['api://b24fc21e-e3f5-48c1-9769-77ed844e7b66/.default'],
  icmApiUrl: 'https://icm.wavelength.host/odata/',
  icmApiScopes: ['api://ad5d6ff0-895d-4d96-aa09-62ddc99c68de/.default'],
  ediPublic: '',
  ediPrivate: ''
};
