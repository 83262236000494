import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType
} from '@azure/msal-browser';

import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MsalInterceptorConfiguration,
  MsalInterceptor,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';

import { msalConfig, protectedResources } from './auth-config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationsComponent } from './applications/applications.component';

import { SharedModule } from './common/shared.module';
import { DatexErrorHandler } from './error/datex-error-handler';
import { ErrorComponent } from './error/error.component';
import { ErrorDialogComponent } from './error/error-dialog.component';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.datexApplicationApi.endpoint, protectedResources.datexApplicationApi.scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
    // this is to be able to set the authority dynamically based on the account tenant
    // In a B2B use case, the user can be a guest user in another AAD. In that case,
    // we want the authority to be the other tenant AAD. So we authenticate against
    // the other tenant and not the home tenant
    authRequest: (msalService, httpReq, req) => {
      return {
        ...req,
        // authority is where to request token from
        authority: `https://login.microsoftonline.com/${req.account?.tenantId ?? 'organizations'}`
      };
    }
  };
}
@NgModule({
  declarations: [
    AppComponent,
    ApplicationsComponent,
    ErrorComponent,
    ErrorDialogComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    MsalModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: DatexErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },

    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
